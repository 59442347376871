$primary-blue-800: #0b1f40;
$primary-blue-700: #325283;

$alfi-red: #e1021d;
$alfi-black: #231f20;

$white: #fff;
$black: #000000;
$gray-900: #656565;
$gray-800: #b2b3b6;
$gray-600: #c4c4c4;
$gray-400: #d1d3d4;
$gray-300: #f5f5f5;
$gray-200: #dbdbdb;

$accent-green: #64be61;
$android-green: #3ddc84;
$stripe-purple: #635bff;
$error-red: #ee2525;

// new theme
$primary-hover: #f60000;
$primary: #d20016;

$gray-default: #626262;
$gray-medium: #949494;
$gray-medium-light: #dadedf;
$gray-light: #f5f5f5;

$male: #2daedf;
$female: #e54960;

$dark: #1a1718;

$success: #16bc5a;
$success-light: #d0f2de;
