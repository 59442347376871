@use 'sass:math';
*,
*::after,
*::before {
  box-sizing: border-box;
}

.logo-spinner {
  display: grid;
  margin: auto;
  opacity: 1;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

.logo-1,
.logo-2,
.logo-3,
.logo-4,
.logo-5 {
  display: inline-block;
  background: black;
}

.logo-1 {
  grid-column: 2 / 7;
  grid-row: 3 / 4;
}

.logo-2 {
  grid-column: 1 / 2;
  grid-row: 3 / 6;
}

.logo-3 {
  grid-column: 2 / 7;
  grid-row: 5 / 6;
}

.logo-4 {
  grid-column: 7 / 8;
  grid-row: 1 / 6;
}

.logo-5 {
  grid-column: 2 / 7;
  grid-row: 1 / 2;
}

.logo-6 {
  float: left;
  background: #e1011b;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

@for $i from 1 through 6 {
  $t: math.div(0.6s, 6) * $i;
  .logo-#{$i} {
    animation: appear 2s ease-out #{$t} backwards infinite;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
