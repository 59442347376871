@import 'styles/scss/colors.scss';

.clear-heading {
  margin: 0;
  font-size: 1rem;
  padding: 0;
}

.simple-header {
  width: 100%;
  height: 4rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    calc(50 * 1deg),
    $alfi-black calc(60 * 1%),
    $alfi-red calc(60 * 1%)
  );
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  .logo-container {
    width: 15rem;

    // @media screen and (max-width: 600px) {
    //   width: 40%;
    //   padding: 1rem;
    // }
    // @media screen and (min-width: 601px) and (max-width: 799px) {
    //   width: 33%;
    //   padding: 1rem;
    // }
    // @media screen and (min-width: 800px) and (max-width: 1199px) {
    //   width: 20%;
    //   padding: 1rem;
    // }
    // @media screen and (min-width: 1200px) {
    //   width: 15rem;
    //   padding: 1rem;
    // }
  }
}
