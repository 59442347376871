@import 'styles/scss/colors.scss';
@import 'styles/scss/rem-calc.scss';

.checkbox-container {
  font-family: 'Red Hat Display';
  user-select: none;
  display: inline-flex;
  position: relative;
  align-items: center;

  > input[type='checkbox'] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  > .label-container {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: rem-calc(30);
    width: max-content;

    > span {
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      width: max-content;
    }
  }
}

.checkbox {
  display: inline-block;
  border-radius: 2px;
  height: 20px;
  width: 20px;
  background: $white;
  border: 2px $gray-medium-light solid;

  > path {
    fill: none;
  }

  &--active {
    border-color: $black;
    background: $black;

    > path {
      fill: $white;
    }
  }
}
