@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap'); // import Roboto & Lato from google fonts API
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700;800&display=swap');
@import './colors.scss';

$default-button-background: #0e3256 !default;
$delete-button-background: #8b011f !default;

$HtmlFontSize: 16px !default;
$bodyFontSize: 14px !default;

$box-spacing-x: 1.5rem !default;
$box-spacing-y: 0 !default;

// /* 0.875 || 87.5% */

:root {
  --input-placeholder-color: #b1b1b1;
  --button-background-default: #{$default-button-background};
  --button-delete-background: #{$delete-button-background};
  --transition-default: all 0.25s ease-in-out;
  --box-spacing-x: 1.5rem;
  --box-spacing-y: 0;
}

html {
  display: block;
  height: 100%;
  font-size: 16px;
}

body {
  align-items: stretch;
  height: 100%;
  margin: 0;
  font-size: 14px;
}

#root {
  width: 100%;
  height: 100%;
}

.navbar__logo {
  width: 150px;
  height: auto;
}

.app {
  width: 100%;
  height: 100%;
}

.nav-fixed {
  position: sticky;
  top: 0;
  left: 0;
}

.nav.bg-primary {
  background: darken(#005fc5, 15%);
}

.nav-item {
  padding: 0;
  text-align: center;
}

.nav-link {
  padding: 1rem !important;
  color: var(--ai-white-200);
  text-transform: uppercase;
  border-bottom: 3px solid transparent;

  &:hover {
    color: var(--ai-white-200);
    background: lighten(#007bff, 15%);
  }

  &.active {
    border-bottom-color: #e31812;
  }
}

.tab-content {
  padding: 1rem;
  background-color: var(--ai-white-200);
}

#root .dashboard {
  // flex-grow: 0;
  width: 100vw;
  max-width: 100%;
}

button {
  cursor: pointer;
}

::-webkit-input-placeholder {
  /* Edge */
  color: val(--input-placeholder-color);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: val(--input-placeholder-color);
}

::placeholder {
  color: val(--input-placeholder-color);
}

// #root .app .dashboard .makeStyles-appBar-4.makeStyles-appBarShift-5 + main {
//  width: calc(100% - 244px);
// }

.MuiPaper-root.MuiDrawer-paper[class='makeStyles-drawerPaper-*'].MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0.dashboard__drawer,
.MuiDrawer-root {
  &.dashboard__drawer {
    z-index: 1060;
    min-height: 100vh;
    background-color: #ddd;
    border-right: 1px #ebebeb solid;
    box-shadow: 1px 0px 10px 1px rgba(0, 0, 0, 0.25);
  }
}

.dashboard__drawer span {
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 500;
}

// erase all this and replace with BS
.MuiButtonBase-root.MuiButton-root.MuiButton-text,
.MuiButton-root {
  padding: 0.7rem 0.75rem !important;
  border-radius: 4px;
  transition: var(--transition-default);

  &:hover {
    text-decoration: none;
    background-color: lighten(#0e3256, 15%);
    transition: var(--transition-default);
  }

  &:focus {
    outline: transparent solid 0;
    // box-shadow: 0 0 0 3px rgba(235, 233, 110, 1);
    transition: var(--transition-default);
  }
}

button:focus {
  outline: transparent solid 0 !important;
  // box-shadow: 0 0 0 3px rgba(235, 233, 110, 1);
  transition: var(--transition-default);
}

.select-container {
  display: grid;
  grid-template-columns: 1px 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'delete-icon-cell select-control';
  max-width: 50%;

  &.active {
    grid-template-columns: 50px 1fr;
  }
}

.select-control {
  grid-area: select-control;
}

.delete-icon-cell {
  grid-area: delete-icon-cell;
  width: 1px;

  &.active {
    width: 50px;
  }
}

.delete-icon-div {
  width: 50px;
}

#theSelectDiv .MuiFormControl-root.MuiFormControl-fullWidth {
  display: flex;
  align-items: center;
  white-space: nowrap;

  .deleteOrg {
    position: absolute;
    top: -6px;
    display: inline-block;
  }

  .MuiFormControl-root.MuiFormControl-fullWidth {
    display: inline-block;
  }
}

.deleteOrg > .MuiIconButton-label > .MuiSvgIcon-root {
  fill: #757575;
}

.MuiPaper-root {
  &.MuiAppBar-root {
    .MuiToolbar-root {
      background-color: transparent;
      border: none;
    }
  }
}

.MuiToolbar-root {
  background: #ebebeb;
  border-top: 2px solid #dedede;
  border-bottom: 2px solid #dedede;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.table td,
.table th {
  vertical-align: middle !important;
}

.MuiTableCell-root {
  display: table-cell;
  padding: 6px 8px;
  font-size: 13px;
  vertical-align: middle !important;
}

.MuiTableCell-body,
.MuiTable-root .MuiTableCell-root[style],
.MuiTable-root .MuiTableCell-root {
  font-size: 14px !important;
  color: #333;
}

[class^='MuiTableHeader-header-'] {
  background-color: transparent;
}

// .MuiTable-root > .MuiTableHead-root,

.MuiButtonBase-root.MuiButton-root.MuiButton-text.sc-dQppl.gQzGqb {
  &:hover {
    background-color: lighten(#0e3256, 15%);
  }
}

.MuiButtonBase-root.MuiTableSortLabel-root {
  font-weight: 700;
}

.css-1wa3eu0-placeholder {
  padding-left: 0.89rem !important;
}

#select {
  display: inline-block;
  white-space: nowrap;
  // padding-left: 0.4rem !important;
}

.text-danger {
  color: var(--ai-danger);
}

.button-delete {
  width: auto;
  height: auto;
  padding: 0.5rem 1rem;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--ai-white-200);
  text-transform: uppercase;
  -webkit-letter-spacing: 1.25px;
  -moz-letter-spacing: 1.25px;
  -ms-letter-spacing: 1.25px;
  letter-spacing: 1.25px;
  background: $delete-button-background;
  border: 2px solid $delete-button-background;
  border-radius: 4px;
  transition: var(--transition-default);

  &:hover {
    background: lighten($delete-button-background, 15%);
    transition: var(--transition-default);
  }

  &:focus {
    background: lighten($delete-button-background, 15%);
    outline: none;
    box-shadow: 0 0 6px 3px rgb(139 9 87 / 50%);
    transition: var(--transition-default);
  }

  &:disabled {
    background-color: desaturate($delete-button-background, 80%);
  }
}

.shadow-inset-md {
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 103px;
  background: var(--ai-gray-100);
  border-top: 2px solid #adadad;
  -webkit-box-shadow: 0 8px 10px -2px rgba(0, 0, 0, 0.25) inset;
  -moz-box-shadow: 0 8px 10px -2px rgba(0, 0, 0, 0.25) inset;
  box-shadow: 0 8px 10px -2px rgb(0 0 0 / 25%) inset;
}

@media (min-width: 600px) {
  .MuiTableCell-root {
    &.MuiTableCell-body {
      &.MuiTableCell-paddingNone {
        > .MuiContainer-root {
          display: flex;
          align-items: stretch;
          min-height: 103px;
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}

#select[style] {
  width: 40% !important;
}

[data-show='true'] {
  display: inline-block;
  opacity: 1;
  transition: all 0.45s ease-in-out;
  transform: scale(1);
}

[data-show='false'] {
  display: none;
  opacity: 0;
  transition: all 0.45s ease-in-out;
  transform: scale(0);
}

.MuiFormControl-root .MuiFormControl-fullWidth {
  border-bottom: 0 solid var(--ai-gray-200);

  &:hover {
    border-bottom: 0 solid #868686 !important;
  }
}

.MuiFormControl-root .MuiFormControl-fullWidth .css-1hivdsp-control {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 38px;
  padding-left: 0.4rem !important;
  margin-right: 25px;
  cursor: pointer;
  background-color: transparent;
  border-color: var(--ai-gray-200) !important;
  border-style: solid;
  border-width: 0;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 0 !important;
  border-radius: 0;
  outline: 0 !important;
  box-shadow: none;
  transition: all 100ms;

  &:hover,
  &:focus {
    border-bottom: solid 0 var(--ai-gray-200) !important;
  }

  .css-ikt7kq-IndicatorsContainer {
    width: 30px;
    height: 30px;
    padding: 1rem;
    background: none;
    border: 2px solid transparent;

    // indicator left separator
    .css-1okebmr-indicatorSeparator {
      background-color: transparent;
      // display: none;
    }
  }
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-yulp85-indicatorContainer.MuiFormControl-root.MuiFormControl-fullWidth.css-11hpcgx-control {
  // border-bottom: 0px solid transparent;
  padding-left: 0.4rem;

  &:hover,
  &:focus {
    padding-left: 0.4rem;
    background: transparent;
    border: 0 solid transparent !important;
    border-bottom-color: transparent !important;
    box-shadow: none;
    transition: background-size 0.2s;
  }

  transition: background-size 0.2s;
}

.css-1gi1mx2-valuecontainer {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .css-1wa3eu0-placeholder {
    .css-b8ldur-input {
      padding-left: 0.4rem;
    }
  }
}

div[class$='indicatorContainer'],
.css-yulp85-indicatorContainer {
  border: 1px solid var(--ai-gray-200);

  &:focus,
  &:focus-within {
    // border: 1px solid var(--ai-gray-200);
    & > svg {
      fill: var(--ai-gray-200);
      outline: 1px solid var(--ai-gray-200);
    }
  }

  & > svg {
    fill: var(--ai-gray-200);
  }
}

.MuiContainer-root[classs^='makeStyles-container-*'].MuiContainer-maxWidthXl {
  position: relative;
}

[id='select'][style],
[id^='simple-tabpanel-*']
  .MuiFormControl-root.MuiTextField-root[class^='MTableToolbar-searchField-*'] {
  display: inline-block;
  // width: 50% !important;
  // border-bottom: 0px solid var(--ai-gray-200);
}

@media only screen and (max-width: 360px) {
  [id='select'][style],
  [id^='simple-tabpanel-*']
    .MuiFormControl-root.MuiTextField-root[class^='MTableToolbar-searchField-*'] {
    width: 100% !important;
    // border-bottom: 0px solid var(--ai-gray-200);
  }
}

.css-2613qy-menu {
  min-width: 120px;
  max-width: 250px;
  min-height: 60px;
  max-height: 350px;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}

.css-1hivdsp-control {
  border: 0 solid transparent !important;
  border-color: transparent !important;

  &:hover {
    background: transparent !important;
    background-color: transparent !important;
  }
}

/* Utility Classes */
.hidding {
  display: none !important;
}

.showing {
  display: block !important; // Im assuming this was supposed to be block and not just an empty value
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

// DropDownMenu
#composition-button {
  text-transform: none;
  color: rgb(255, 255, 255);
  font: Roboto sans-serif;
  font-weight: 700;
  letter-spacing: 0.0075em;
  font-size: 1rem;
  min-width: 200px;
}
.no-flex {
  flex: none;
}

[hidden],
.hidden {
  display: none;
}

.alfi-nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.border-bottom-gray300 {
  border-bottom: 1px solid var(--ai-form-control-border-color);
}

.alfi-box-container {
  width: 100%;
  padding-right: var(--box-spacing-x);
  padding-left: var(--box-spacing-x);
  margin-right: auto;
  margin-left: auto;

  .alfi-box-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc($box-spacing-y * -1);
    margin-right: calc($box-spacing-x * -0.5);
    margin-left: calc($box-spacing-x * -0.5);

    & > *:not([class^='alfi-button']),
    & > *:not([class^='alfi-box-']) {
      flex-shrink: 0;
      max-width: 100%;
      padding-right: calc($box-spacing-x * 0.5);
      padding-left: calc($box-spacing-x * 0.5);
      margin-top: var(--box-spacing-y);
    }

    .alfi-box {
      flex: 1 0;
    }
  }
}

.alfi-box-auto {
  flex: 0 0 auto;
  width: auto;
}

.alfi-box-1of12 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.alfi-box-2of12 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.alfi-box-3of12 {
  flex: 0 0 auto;
  width: 25%;
}

.alfi-box-4of12 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.alfi-box-5of12 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.alfi-box-6of12 {
  flex: 0 0 auto;
  width: 50%;
}

.alfi-box-7of12 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.alfi-box-8of12 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.alfi-box-9of12 {
  flex: 0 0 auto;
  width: 75%;
}

.alfi-box-10of12 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.alfi-box-11of12 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.alfi-box-12of12,
.alfi-box-full {
  flex: 0 0 auto;
  width: 100%;
}

.alfi-box-nowrap {
  flex-wrap: nowrap;
  white-space: nowrap;
}
.dropdown-menu {
  padding: 0;
}

/* show / hide form fields based off state of a checkbox */
.dropdown-item {
  padding: 0;
}
.dropdown-menu {
  border: 1px solid #ccc;
}
#CapTypeCheckbox {
  .revenueCapping {
    display: none;
  }
}

#CapTypeCheckbox[checked='true'] {
  .revenueCapping {
    display: block;
  }
}
.alfi-button-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.alfi-button-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  &:hover {
    color: #fff;
    background-color: var(--ai-danger);
    border-color: var(--ai-danger);
  }
  &:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
  }
  &.active {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
    }
  }
  &:active {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
    }
  }
  &.disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  &:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
}

.alfi-button-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  &:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
  }
  &:focus {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
  }
  &.active {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
    }
  }
  &:active {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
    }
  }
  &.disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
  &:disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
}

.alfi-button-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  &:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
  }
  &:focus {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
  }
  &.active {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
    }
  }
  &:active {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
    }
  }
  &.disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  &:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
}
.alfi-button-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
  &:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
  }
  &:focus {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
  }
  &.active {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
    }
  }
  &:active {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
    }
  }
  &.disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
  }
  &:disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
  }
}
.alfi-button-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  &:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2;
  }
  &:focus {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2;
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
  }
  &.active {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
    }
  }
  &:active {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
    }
  }
  &.disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
  }
  &:disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
  }
}
.alfi-button-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
  &:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
  }
  &:focus {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
  }
  &.active {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
    }
  }
  &:active {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
    }
  }
  &.disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  &:disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
  }
}
.alfi-button-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  &:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
  }
  &:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
  }
  &.active {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
    }
  }
  &:active {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
    }
  }
  &.disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  &:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
}
.alfi-button-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  &:hover {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
  }
  &:focus {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
  }
  &.active {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
    }
  }
  &:active {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
    }
  }
  &.disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  &:disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
}

.alfi-button-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
  &:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
  }
  &:focus {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
  }
  &.active {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
    }
  }
  &:active {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
    }
  }
  &.disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
  }
  &:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
  }
}
.alfi-button-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
  &:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
  }
  &.active {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
    }
  }
  &:active {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
    }
  }
  &.disabled {
    color: #0d6efd;
    background-color: transparent;
  }
  &:disabled {
    color: #0d6efd;
    background-color: transparent;
  }
  &.dropdown-toggle.show {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
    }
  }
}
.alfi-button-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
  &:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
  }
  &.active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
    }
  }
  &:active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
    }
  }
  &.disabled {
    color: #6c757d;
    background-color: transparent;
  }
  &:disabled {
    color: #6c757d;
    background-color: transparent;
  }
  &.dropdown-toggle.show {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
    }
  }
}
.alfi-button-outline-success {
  color: #198754;
  border-color: #198754;
  &:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
  }
  &.active {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
    }
  }
  &:active {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
    }
  }
  &.disabled {
    color: #198754;
    background-color: transparent;
  }
  &:disabled {
    color: #198754;
    background-color: transparent;
  }
  &.dropdown-toggle.show {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
    }
  }
}
.alfi-button-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
  &:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
  }
  &.active {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
    }
  }
  &:active {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
    }
  }
  &.disabled {
    color: #0dcaf0;
    background-color: transparent;
  }
  &:disabled {
    color: #0dcaf0;
    background-color: transparent;
  }
  &.dropdown-toggle.show {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
    }
  }
}
.alfi-button-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
  &:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
  }
  &.active {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
    }
  }
  &:active {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
    }
  }
  &.disabled {
    color: #ffc107;
    background-color: transparent;
  }
  &:disabled {
    color: #ffc107;
    background-color: transparent;
  }
  &.dropdown-toggle.show {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
    }
  }
}
.alfi-button-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
  &:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
  }
  &.active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
    }
  }
  &:active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
    }
  }
  &.disabled {
    color: #dc3545;
    background-color: transparent;
  }
  &:disabled {
    color: #dc3545;
    background-color: transparent;
  }
  &.dropdown-toggle.show {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
    }
  }
}
.alfi-button-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
  &:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
  }
  &.active {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
    }
  }
  &:active {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
    }
  }
  &.disabled {
    color: #f8f9fa;
    background-color: transparent;
  }
  &:disabled {
    color: #f8f9fa;
    background-color: transparent;
  }
  &.dropdown-toggle.show {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
    }
  }
}
.alfi-button-outline-dark {
  color: #212529;
  border-color: #212529;
  &:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
  }
  &.active {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
    }
  }
  &:active {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
    }
  }
  &.disabled {
    color: #212529;
    background-color: transparent;
  }
  &:disabled {
    color: #212529;
    background-color: transparent;
  }
  &.dropdown-toggle.show {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
    }
  }
}
.alfi-button-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
  &:hover {
    color: #0a58ca;
  }
  &.disabled {
    color: #6c757d;
  }
  &:disabled {
    color: #6c757d;
  }
}
.alfi-button-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.alfi-button-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.fade {
  transition: opacity 0.15s linear;
}
.show > {
  .alfi-button-primary.dropdown-toggle {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
    }
  }
  .alfi-button-secondary.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
    }
  }
  .alfi-button-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
    }
  }
  .alfi-button-info.dropdown-toggle {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
    }
  }
  .alfi-button-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
    }
  }
  .alfi-button-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
    }
  }
  .alfi-button-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
    }
  }
  .alfi-button-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
    }
  }
}

.fade {
  transition: opacity 0.15s linear;
}

.loader-container {
  align-items: center;
  background: $gray-light;
  position: absolute;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1202;
}
