@import 'styles/scss/colors.scss';
@import 'styles/scss/rem-calc.scss';
@import '../text/text.scss';

.form-group-input {
  display: flex;
  flex-direction: column;
  gap: rem-calc(4);

  label {
    @extend .body-medium;

    color: $dark;
  }

  &--icon {
    position: relative;
    display: flex;
    align-items: center;

    .form-group-input--password & {
      > span {
        padding-left: 0;
        padding-right: rem-calc(12);
        right: 0;
        left: unset;
      }

      > input.form-input {
        padding: rem-calc(8);
        padding-right: rem-calc(40);
      }
    }

    > span {
      position: absolute;
      left: 0;
      padding-left: rem-calc(12);
      color: $gray-default;
      display: inline-flex;
    }

    > input.form-input {
      padding-left: rem-calc(40);
    }
  }
}

.form-input {
  background-color: $white;
  outline: none;
  border: 1px $gray-medium-light solid;
  padding: rem-calc(8);
  border-radius: rem-calc(4);
  transition: border-color 0.2s ease-in-out;
  width: 100%;

  @extend .body-medium;

  &:not(:disabled) {
    &:hover,
    &:focus {
      border-color: $gray-medium;
    }
  }

  &::placeholder {
    color: $gray-default;

    @extend .body-medium;
  }
}
