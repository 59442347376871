.number {
  text-decoration: none;
  color: black;
}

.nest {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.phone {
  font-size: 1rem;
  margin-top: 0.15rem;
}
