@import 'FontFamilies';
@import './colors.scss';
.dashboard {
  display: flex;
  &__header {
    background: linear-gradient(
      calc(50 * 1deg),
      $alfi-black calc(60 * 1%),
      $alfi-red calc(60 * 1%)
    );
  }
  &__drawer {
    border-right: 4px solid #0b1f40;
    background: #333333;
    span {
      font-family: 'Raleway', sans-serif;
      font-weight: 500;
    }
  }
}

.listItems {
  &__item {
  }
  &__subheader {
    font-family: 'Raleway', sans-serif !important;
    font-weight: 900 !important;
    font-size: 18px !important;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
}

.login-logo {
  width: 100%;
}

.driver-list {
  height: 100%;
}

.lang-select {
  height: 40px;
}
