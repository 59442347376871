@import 'styles/scss/colors.scss';
@import 'styles/scss/rem-calc.scss';
@import '../text/text.scss';

.btn {
  border-radius: 4px;
  border: none;
  cursor: pointer;
  justify-content: center;
  outline: none;
  padding: rem-calc(8) rem-calc(14);
  transition: all 0.2s ease-in;
  transition-property: background-color, color;
  display: flex;
  align-items: center;
  min-height: rem-calc(40);

  @extend .body-medium;

  > *:not(:last-child) {
    margin-right: rem-calc(10);
  }

  &:active:not(:disabled) {
    transform: scale(0.99);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $gray-medium-light;
    color: $gray-medium;
    outline: none;
  }

  &-outline {
    color: $primary;
    outline: 1px solid $primary;
    background-color: $white;

    &:hover:not(:disabled) {
      color: $primary-hover;
      outline-color: $primary-hover;
    }
  }

  &-filled {
    background-color: $primary;
    color: $white;

    &:hover:not(:disabled) {
      background-color: $primary-hover;
    }
  }
}
