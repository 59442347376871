@import 'styles/scss/rem-calc.scss';

.font-primary {
  font-family: 'Red Hat Display', sans-serif;
}

.h1-extra-bold {
  @extend .font-primary;
  font-weight: 700;
  font-size: rem-calc(48);
  line-height: rem-calc(64);
}

.h2-bold {
  @extend .font-primary;
  font-weight: 700;
  font-size: rem-calc(36);
  line-height: rem-calc(48);
}

.h3-extrabold {
  @extend .font-primary;
  font-weight: 800;
  font-size: rem-calc(30);
  line-height: rem-calc(40);
}

.h4-bold {
  @extend .font-primary;
  font-weight: 700;
  font-size: rem-calc(20);
  line-height: rem-calc(26);
}

.h4-semibold {
  @extend .font-primary;
  @extend .h4-bold;
  font-weight: 600;
}

.h5-regular {
  @extend .font-primary;
  font-weight: 400;
  font-size: rem-calc(17);
  line-height: rem-calc(22);
}

.h5-semibold {
  @extend .font-primary;
  @extend .h5-regular;
  font-weight: 600;
}

.h5-extra-bold {
  @extend .font-primary;
  @extend .h5-regular;
  font-weight: 800;
}

.body-regular {
  @extend .font-primary;
  font-weight: 400;
  font-size: rem-calc(15);
  line-height: rem-calc(20);
}

.body-medium {
  @extend .font-primary;
  @extend .body-regular;
  font-weight: 500;
}

.body-bold {
  @extend .font-primary;
  @extend .body-regular;
  font-weight: 700;
}

.body-regular-sm {
  @extend .font-primary;
  font-weight: 400;
  font-size: rem-calc(13);
  line-height: rem-calc(17);
}

.body-semibold-sm {
  @extend .font-primary;
  @extend .body-regular-sm;
  font-weight: 600;
}

.body-extrabold-sm {
  @extend .font-primary;
  @extend .body-regular-sm;
  font-weight: 800;
}

.body-regular-xs {
  @extend .font-primary;
  font-weight: 400;
  font-size: rem-calc(11);
  line-height: rem-calc(15);
}

.body-medium-xs {
  @extend .font-primary;
  @extend .body-regular-xs;
  font-weight: 500;
}
